import React, { useRef, useEffect } from "react";
import "./style.css";

const FooterDown = () => {
  const footerRef = useRef(null);

  useEffect(() => {
    const footerSection = footerRef.current;
    let timeoutId;

    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const rect = footerSection.getBoundingClientRect();
        const viewHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (rect.top <= viewHeight && rect.bottom >= 0) {
          footerSection.classList.add(
            "animate__animated",
            // "animate__fadeInUp"
            // "animate__backInLeft"
            "animate__lightSpeedInLeft"
            // "animate__lightSpeedInRight"
          );
        } else {
          footerSection.classList.remove(
            "animate__animated",
            // "animate__fadeInUp"
            // "animate__backInLeft"
            "animate__lightSpeedInLeft"
            // "animate__lightSpeedInRight"
          );
        }
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="footerDown">
        <p className="footerDown-text" ref={footerRef}>
          <span className="footerDown-text-first">
            DYOR is a first of its kind global research collective.
          </span>
          <span className="footerDown-text-second">
            DO YOUR OWN RESEARCH 2024.
          </span>
        </p>
      </div>
    </>
  );
};

export default FooterDown;
