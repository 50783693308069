import React, { useRef, useEffect } from "react";
import "./style.css";

const Template = ({ heading, logo, data, color }) => {
  const templateRef = useRef(null);

  useEffect(() => {
    const templateSection = templateRef.current;
    let timeoutId;

    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const rect = templateSection.getBoundingClientRect();
        const viewHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (rect.top <= viewHeight && rect.bottom >= 0) {
          templateSection.classList.add(
            "animate__animated",
            // "animate__flip",
            "animate__lightSpeedInLeft",
            "animate__slow"
            // "animate__slower"
          );
        } else {
          templateSection.classList.remove(
            "animate__animated",
            // "animate__flip",
            "animate__lightSpeedInLeft",
            "animate__slow"
            // "animate__slower"
          );
        }
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="template" ref={templateRef}>
        <div className="template-section">
          <div className="template-section-heading">
            <h1 style={{ color: color }}>{heading}</h1>
            <img src={logo} alt="logo" />
          </div>
          <p>{data}</p>
        </div>
      </div>
    </>
  );
};

export default Template;
