import React, { useRef, useEffect } from "react";
import "./style.css";
import Template from "../template/index";
import Phase from "../phase/index";
import Smiley from "../../assets/newAssets/Group 8.png";

const Roadmap = () => {
  const templateRef = useRef(null);

  useEffect(() => {
    const templateSection = templateRef.current;
    let timeoutId;

    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const rect = templateSection.getBoundingClientRect();
        const viewHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (rect.top <= viewHeight && rect.bottom >= 0) {
          templateSection.classList.add(
            "animate__animated",
            "animate__flipInX",
            "animate__slow"
          );
        } else {
          templateSection.classList.remove(
            "animate__animated",
            "animate__flipInX",
            "animate__slow"
          );
        }
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="roadmap">
        <div>
          <Template
            heading="ROADMAP"
            logo={Smiley}
            data="A Crypto roadmap ain't fortune cookie. It's the project's game plan! See your coin's rollin', new features droppin', problems gettin' squashed. Roadmap = crystal ball for crypto!"
            color="#0E0E0E"
          />
        </div>
        <div className="roadmap-phase" ref={templateRef}>
          <Phase
            phase="PHASE 1"
            para="March 2024"
            text1="$DYOR Birth"
            text2="Website Launch"
            text3="Community Building"
            text4="Marketing"
          />
          <Phase
            phase="PHASE 2"
            para="April 2024"
            text1="Stealth launch"
            text2="$DYOR Launch"
            text3="CMC/CG Fast Apply"
            text4="Gamification"
          />
          <Phase
            phase="PHASE 3"
            para="May 2024"
            text1="Community Expand"
            text2="Merch Party"
            text3="Merch Product"
            text4="CertiK Audit Process"
          />
        </div>
      </div>
    </>
  );
};

export default Roadmap;
