import React from "react";
import "./style.css";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const Index = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleItemClick = () => {
    setOpen(false);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="menu">
        <MenuIcon
          sx={{ color: "white" }}
          fontSize="large"
          onClick={toggleDrawer(true)}
        />
        <Drawer open={open} onClose={toggleDrawer(false)}>
          <List>
            <ListItem>
              {/* <Link to="top" smooth={true} duration={1000}>
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </Link> */}
              <RouterLink
                to="/"
                className="menu-item"
                onClick={() => scrollToSection("top")}
              >
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </RouterLink>
            </ListItem>
            <ListItem>
              <RouterLink
                to="/dashboard"
                className="menu-item"
                onClick={() => scrollToSection("dashboard")}
              >
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </RouterLink>
            </ListItem>
            <ListItem>
              <RouterLink
                className="menu-item"
                to="/#about"
                onClick={() => scrollToSection("about")}
              >
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="About" />
                </ListItemButton>
              </RouterLink>
              {/* <Link to="about" smooth={true} duration={1000}>
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="About" />
                </ListItemButton>
              </Link> */}
            </ListItem>
            <ListItem>
              <RouterLink
                to="/#tokenomics"
                className="menu-item"
                onClick={() => scrollToSection("tokenomics")}
              >
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="Tokenomics" />
                </ListItemButton>
              </RouterLink>
              {/* <Link to="tokenomics" smooth={true} duration={1000}>
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="Tokenomics" />
                </ListItemButton>
              </Link> */}
            </ListItem>
            <ListItem>
              <RouterLink
                to="/#roadmap"
                className="menu-item"
                onClick={() => scrollToSection("roadmap")}
              >
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="Roadmap" />
                </ListItemButton>
              </RouterLink>
              {/* <Link to="roadmap" smooth={true} duration={1000}>
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="Roadmap" />
                </ListItemButton>
              </Link> */}
            </ListItem>
            <ListItem>
              <Link to="mission" smooth={true} duration={1000}>
                <ListItemButton onClick={handleItemClick}>
                  <ListItemText primary="Our Mission" />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
        </Drawer>
      </div>
    </>
  );
};

export default Index;
