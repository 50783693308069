import React from "react";
import "./style.css";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";

const LeaderBoard = () => {
  return (
    <>
      <div className="leaderBoard">
        <h1>LEADERBOARD</h1>
        <div className="leaderBoard-list">
          <div className="leaderBoard-list-item">
            <div className="leaderBoard-list-item-left">
              <div>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </div>
              <div>
                <h4>Raf</h4>
                <p>@notrafsburner</p>
              </div>
            </div>
            <div>
              <h3>3.5m</h3>
            </div>
          </div>
          <div className="leaderBoard-list-item">
            <div className="leaderBoard-list-item-left">
              <div>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </div>
              <div>
                <h4>Kawley</h4>
                <p>@joechawley</p>
              </div>
            </div>
            <div>
              <h3>2.2m</h3>
            </div>
          </div>
          <div className="leaderBoard-list-item">
            <div className="leaderBoard-list-item-left">
              <div>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </div>
              <div>
                <h4>DOGGTOR.eth</h4>
                <p>@javmdpr</p>
              </div>
            </div>
            <div>
              <h3>2.0m</h3>
            </div>
          </div>
        </div>
        <div className="leaderBoard-bottom">
          <p>Ranks are updated hourly</p>
          <Button variant="contained">View All</Button>
        </div>
      </div>
    </>
  );
};

export default LeaderBoard;
