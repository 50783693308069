import React, { useEffect } from "react";
import "./style.css";
import Balance from "../../components/balance/index";
import Meter from "../../components/meter/index";
import Level from "../../components/level/index";
import Profile from "../../components/profile/index";
import LeaderBoard from "../../components/leaderBoard/index";
import Link from "../../components/link/index";

const Dashboard = () => {

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])
  return (
    <>
      {/* <div className="dashboard-section" id="dashboard"> */}
      <div className="dashboard-section">
        <h1 className="dashboard-section-heading">Dashboard</h1>
        <div className="dashboard">
          <div className="dashboard-left">
            <Balance />
            <Profile />
            <LeaderBoard />
          </div>
          <div className="dashboard-mid">
            <Meter />
            <Link />
          </div>
          <div className="dashboard-right">
            <Level />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
