import React from "react";
import "./style.css";

import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import FavoriteIcon from "@mui/icons-material/Favorite";

const Balance = () => {
  return (
    <>
      <div className="balance">
        <h1>BALANCE</h1>
        <p>Gutter Chips</p>
        <h1>0</h1>
        <p>Multipliers Enabled</p>
        <div className="balance-bottom">
          <OfflineBoltIcon />
          <span>1x Power</span>
          <FavoriteIcon />
          <span>1x Level</span>
        </div>
      </div>
    </>
  );
};

export default Balance;
