import React, { useRef, useEffect } from "react";
import "./style.css";
import Logo1 from "../../assets/newAssets/Vector (6).png";
import Logo2 from "../../assets/newAssets/Group 38.png";
import Logo3 from "../../assets/newAssets/Vector (7).png";
import Logo4 from "../../assets/newAssets/Group 37.png";
import Logo5 from "../../assets/newAssets/Group 76.png";
import Logo6 from "../../assets/newAssets/Vector (8).png";
import Logo7 from "../../assets/newAssets/katman 1-2.png";
import Tooltip from '@mui/material/Tooltip';

const FooterMid = () => {
  const footerRef = useRef(null);

  useEffect(() => {
    const footerSection = footerRef.current;
    let timeoutId;

    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const rect = footerSection.getBoundingClientRect();
        const viewHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (rect.top <= viewHeight && rect.bottom >= 0) {
          footerSection.classList.add(
            "animate__animated",
            // "animate__fadeInUp"
            // "animate__backInLeft"
            // "animate__lightSpeedInLeft"
            "animate__lightSpeedInRight"
          );
        } else {
          footerSection.classList.remove(
            "animate__animated",
            // "animate__fadeInUp"
            // "animate__backInLeft"
            // "animate__lightSpeedInLeft"
            "animate__lightSpeedInRight"
          );
        }
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="footerMid">
        <div className="footerMid-parent" ref={footerRef}>
        <Tooltip title="Coming Soon.." placement="top-start">
          <div className="footerMid-child">
            <img src={Logo1} alt="" />
            <span>DexTool Chart</span>
          </div>
          </Tooltip>
          <Tooltip title="Coming Soon.." placement="top-start">
          <div className="footerMid-child">
            <img src={Logo2} alt="" />
            <span>Dex Screener</span>
          </div>
          </Tooltip>
          <Tooltip title="Coming Soon.." placement="top-start">
          <div className="footerMid-child">
            <img src={Logo3} alt="" />
            <span>Coin Marketcap</span>
          </div>
          </Tooltip>
          <Tooltip title="Coming Soon.." placement="top-start">
          <div className="footerMid-child">
            <img src={Logo4} alt="" />
            <span>Coin Gecko</span>
          </div>
          </Tooltip>
          <Tooltip title="Coming Soon.." placement="top-start">
          <div className="footerMid-child">
            <img src={Logo5} alt="" />
            <span>Jupiter Swap</span>
          </div>
          </Tooltip>
          <Tooltip title="Coming Soon.." placement="top-start">
          <div className="footerMid-child">
            <img src={Logo6} alt="" />
            <span>Raydium</span>
          </div>
          </Tooltip>
          <Tooltip title="Coming Soon.." placement="top-start">
          <div className="footerMid-child">
            <img src={Logo7} alt="" />
            <span>Compare Marketcap</span>
          </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default FooterMid;
