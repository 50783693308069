import "./App.css";
import Header from "./components/header/index";
import FooterUp from "./components/footerUp/index";
import FooterMid from "./components/footerMid/index";
import FooterDown from "./components/footerDown/index";
import Home from "./pages/home/index";
import Dashboard from "./pages/dashboard/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      {/* <script id="wow" src="/js/wow.min.js"></script> */}
      {/* <Home /> */}
      <Router>
        <section id="top">
          <Header />
        </section>
        <Routes>
          <Route path="/" element={<Home />} />
          
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
        <FooterUp />
        <FooterMid />
        <section id="mission">
          <FooterDown />
        </section>
      </Router>
    </>
  );
}

export default App;
