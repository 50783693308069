import React from "react";
import "./style.css";
import PhaseButton from "../phaseButton/index";

const Phase = ({ phase, para, text1, text2, text3, text4 }) => {
  return (
    <>
      <div className="phase">
        <h1>{phase}</h1>
        <p>{para}</p>

        <PhaseButton text={text1} />
        <PhaseButton text={text2} />
        <PhaseButton text={text3} />
        <PhaseButton text={text4} />
      </div>
    </>
  );
};

export default Phase;
