import React from "react";
import "./style.css";

import Button from "@mui/material/Button";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const Link = () => {
  return (
    <>
      <div className="link">
        <div className="link-left">
          <GroupAddIcon />
          <div className="link-left-text">
            <p>My Referral Link</p>
            <h4>https://twitter.com/DYORNetwork</h4>
          </div>
        </div>
        <div className="link-right">
          <Button variant="contained">Copy Link</Button>
        </div>
      </div>
    </>
  );
};

export default Link;
