import React from "react";
import "./style.css";

import "react-circular-progressbar/dist/styles.css";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

const Meter = () => {
  return (
    <>
      <div className="meter">
        <CircularProgressbarWithChildren
          value={2}
          strokeWidth={1}
          circleRatio={0.75}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,

            pathColor: "white",
            trailColor: "grey",
          })}
        >
          <div style={{ width: "90%" }}>
            <CircularProgressbarWithChildren
              background="true"
              backgroundPadding="10"
              strokeWidth="10"
              value={1}
              text={1}
              circleRatio={0.75}
              styles={buildStyles({
                rotation: 1 / 2 + 1 / 8,
                strokeLinecap: "round",
                trailColor: "grey",
                pathColor: "#fd3eb6",
                textSize: "26px",
                textColor: "white",
                backgroundColor: "transparent",
              })}
            >
              <div style={{ width: "50%" }}>
                <CircularProgressbar
                  value={2}
                  strokeWidth={1}
                  circleRatio={0.75}
                  styles={buildStyles({
                    rotation: 1 / 2 + 1 / 8,

                    pathColor: "white",
                    trailColor: "grey",
                  })}
                />
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </CircularProgressbarWithChildren>
        <div className="meter-bottom">
          <div className="meter-bottom-left">0</div>
          <div className="meter-bottom-mid">
            <p>Current Gutter Chips</p>
            <h2>0</h2>
          </div>
          <div className="meter-bottom-right">25k</div>
        </div>
      </div>
    </>
  );
};

export default Meter;
