import React from "react";
import "./style.css";

import Button from "@mui/material/Button";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const Profile = () => {
  return (
    <>
      <div className="profile">
        <div className="profile-left">
          <ArrowCircleUpIcon />
          <div className="profile-left-text">
            <p>My Rank</p>
            <h2>5,365</h2>
          </div>
        </div>
        <div className="profile-right">
          <Button variant="contained">My Profile</Button>
        </div>
      </div>
    </>
  );
};

export default Profile;
