import React from "react";
import "./style.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import React from "react";
import Slider from "react-slick";
import LockIcon from "@mui/icons-material/Lock";

const Level = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="level">
        <h1>CURRENT LEVEL 1</h1>
        {/* <div> */}
        <div className="slider-container">
          <Slider {...settings}>
            <div className="level-card">
              <p>DYOR</p>
              <h1>ROOKIE</h1>
              <LockIcon />
              <div className="level-card-box">
                <div className="level-card-box-top">
                  <div>Next Level</div>
                  <div>25.0k</div>
                </div>
                <p>0</p>
                <input></input>
              </div>
            </div>
            <div className="level-card">
              <p>DYOR</p>
              <h1>ROOKIE</h1>
              <LockIcon />
              <div className="level-card-box">
                <div className="level-card-box-top">
                  <div>Next Level</div>
                  <div>25.0k</div>
                </div>
                <p>1</p>
                <input></input>
              </div>
            </div>
            <div className="level-card">
              <p>DYOR</p>
              <h1>ROOKIE</h1>
              <LockIcon />
              <div className="level-card-box">
                <div className="level-card-box-top">
                  <div>Next Level</div>
                  <div>25.0k</div>
                </div>
                <p>2</p>
                <input></input>
              </div>
            </div>
            <div className="level-card">
              <p>DYOR</p>
              <h1>ROOKIE</h1>
              <LockIcon />
              <div className="level-card-box">
                <div className="level-card-box-top">
                  <div>Next Level</div>
                  <div>25.0k</div>
                </div>
                <p>3</p>
                <input></input>
              </div>
            </div>
            <div className="level-card">
              <p>DYOR</p>
              <h1>ROOKIE</h1>
              <LockIcon />
              <div className="level-card-box">
                <div className="level-card-box-top">
                  <div>Next Level</div>
                  <div>25.0k</div>
                </div>
                <p>4</p>
                <input></input>
              </div>
            </div>
            <div className="level-card">
              <p>DYOR</p>
              <h1>ROOKIE</h1>
              <LockIcon />
              <div className="level-card-box">
                <div className="level-card-box-top">
                  <div>Next Level</div>
                  <div>25.0k</div>
                </div>
                <p>5</p>
                <input></input>
              </div>
            </div>
          </Slider>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Level;
