import React, { useRef, useEffect } from "react";
import "./style.css";
import Research from "../../assets/newAssets/Vector (1).svg";
import JoinButton from "../joinButton/index";

const Join = () => {
  const joinRef = useRef(null);

  useEffect(() => {
    const joinSection = joinRef.current;
    let timeoutId;

    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const rect = joinSection.getBoundingClientRect();
        const viewHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (rect.top <= viewHeight && rect.bottom >= 0) {
          joinSection.classList.add("animate__animated", "animate__zoomIn");
        } else {
          joinSection.classList.remove("animate__animated", "animate__zoomIn");
        }
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="join">
        <div className="join-section" ref={joinRef}>
          <div className="join-section-left">
            <h2>
              <span>#DYOR</span> Buckle up for the Crypto & Cannabis Digital
              Revolution.
            </h2>
            <h2>
              We're OG tastemakers with the tech savy to reimagine how you
              interact with the things you love. Forget FOMO, this is a
              movement.
            </h2>
            <h2>
              DYOR is your golden ticket to this exclusive digital fam, a badge
              of honor for the self-made, future-focused you.
            </h2>
          </div>
          <div className="join-section-right">
            <img src={Research} alt="Research" />
            <a href="https://t.me/theDYORNetwork" target="blank">
            <JoinButton
              text="JOIN OUR COMMUNITY"
              colour="#0372CC"
              textColor="#FEE727"
            />
            </a>
            {/* <JoinButton
              text="JOIN THE PRESALE"
              colour="#FD3EB6"
              textColor="#FFF"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Join;
