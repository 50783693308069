import React from "react";
import "./style.css";
import Mainlogo from "../../assets/main3.png";
// import Mainlogo from "../../assets/newAssets/Group 23.png";
// import LeftButton from "../../assets/newAssets/Group 80.png";
// import RightButton from "../../assets/newAssets/Group 79.png";
import TopLeaf from "../../assets/newAssets/Vector (3).png";
import LeftLeaf from "../../assets/newAssets/Vector (1).png";
import RightLeaf from "../../assets/newAssets/Vector (2).png";

// import Prism from "../../assets/Frame.png";
// import Circle from "../../assets/47.png";
// import Line from "../../assets/Subtract.png";
// import Blast from "../../assets/New/pngImages/blast+token.png";

import HeroButton from "../heroButton/index";
import Tooltip from '@mui/material/Tooltip';

const Index = () => {
  return (
    <>
      <div className="front">
        {/* <img src={TopLeaf} className="front-prism" alt="TopLeaf" /> */}
        <div className="front-section">
          <div className="front-left">
            <div className="front-left-heading">
              <h1 className="animate__animated animate__fadeInLeftBig front-left-heading-top">
                MEME PACKS
              </h1>
              <h1 className="animate__animated animate__fadeInRight">Crypto</h1>
              <h1 className="animate__animated animate__fadeInLeftBig">
                Cannabis
              </h1>
              <h1 className="animate__animated animate__fadeInRight">
                Culture
              </h1>
            </div>
            {/* <p className="animate__animated animate__backInLeft">
              Don't let life pass you by - Take It!
            </p> */}
            <div className="front-left-button animate__animated animate__backInDown">
            <Tooltip title="Coming Soon.." placement="top-start">
              <button className="front-left-button-1">

                <HeroButton colour="#95D701" text="WHITEPAPER" />
              </button>
            </Tooltip>
            <Tooltip title="Coming Soon.." placement="top-start">
              <button className="front-left-button-2">
                {/* <img src={RightButton} alt="RightButton" /> */}
                <HeroButton colour="#FD3EB6" text="MEME SWAP" />
              </button>
              </Tooltip>
            </div>
            <div className="front-left-bottom-text animate__animated animate__backInDown">
              <span>CA:</span>
              <span className="front-left-bottom-text-second">
                COMING SOON!
              </span>
            </div>
          </div>

          <div className="front-right animate__animated animate__fadeInRightBig">
            <img src={TopLeaf} className="top-leaf" alt="TopLeaf" />
            <img src={Mainlogo} className="front-right-image" alt="Circle" />
            <img src={LeftLeaf} className="left-leaf" alt="TopLeaf" />
          </div>
        </div>
        <img src={RightLeaf} className="right-leaf" alt="line" />
      </div>
    </>
  );
};

export default Index;
