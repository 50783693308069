import React from "react";
import "./style.css";

const Index = ({ colour, text }) => {
  return (
    <>
      <div className="heroButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="286"
          height="73"
          viewBox="0 0 286 73"
          fill="none"
        >
          <path
            d="M283.95 70.0464C256.009 76.692 255.73 70.0464 227.517 70.0464C208.703 70.0464 189.892 70.0464 171.084 70.0464C152.275 70.0464 133.464 70.0464 114.651 70.0464C95.8372 70.0464 77.0238 70.0464 58.2104 70.0464C29.9903 70.0464 29.7108 76.692 1.77021 70.0464C-2.20081 69.0987 1.77021 63.3407 1.77021 56.635C1.77021 49.9293 1.77021 49.9293 1.77021 43.2117C1.77021 36.494 1.77021 36.506 1.77021 29.8003C1.77021 23.0947 1.77021 23.0827 1.77021 16.377C1.77021 9.67131 -2.20798 3.90131 1.76304 2.95364C29.7036 -3.69205 29.9831 2.95364 58.1961 2.95364H227.509C255.73 2.95364 256.009 -3.69205 283.95 2.95364C287.921 3.90131 283.95 9.65931 283.95 16.365C283.95 23.0707 283.95 23.0707 283.95 29.7883C283.95 36.506 283.95 36.494 283.95 43.1997C283.95 49.9053 282.953 50.1213 283.95 56.623C285.104 64.1324 287.921 69.0987 283.95 70.0464Z"
            fill={colour}
          />
          <text
            // x="35"
            x="143"
            y="55"
            font-size="48"
            fill="#562F92"
            text-anchor="middle"
            className="custom-text"
          >
            {text}
          </text>
        </svg>
      </div>
    </>
  );
};

export default Index;
