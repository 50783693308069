// import React, { useRef, useEffect } from "react";
// import "./style.css";

// const FooterUp = () => {
//   const footerRef = useRef(null);

//   useEffect(() => {
//     const footerSection = footerRef.current;
//     let timeoutId;

//     const handleScroll = () => {
//       if (timeoutId) {
//         clearTimeout(timeoutId);
//       }

//       timeoutId = setTimeout(() => {
//         const rect = footerSection.getBoundingClientRect();
//         const viewHeight =
//           window.innerHeight || document.documentElement.clientHeight;
//         if (rect.top <= viewHeight && rect.bottom >= 0) {
//           footerSection.classList.add(
//             "animate__animated",
//             // "animate__fadeInUp"
//             // "animate__backInLeft"
//             "animate__lightSpeedInLeft"
//             // "animate__lightSpeedInRight"
//           );
//         } else {
//           footerSection.classList.remove(
//             "animate__animated",
//             // "animate__fadeInUp"
//             // "animate__backInLeft"
//             "animate__lightSpeedInLeft"
//             // "animate__lightSpeedInRight"
//           );
//         }
//       }, 100);
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <>
//       <div className="footerUp">
//         <p ref={footerRef}>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//           <span>$DYOR</span>
//         </p>
//       </div>
//     </>
//   );
// };

// export default FooterUp;

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import Slider from "react-slick";
import "./style.css";

function AutoPlay() {
  const settings = {
    // dots: true,
    dots: false,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const sliderContainerStyle = {
    backgroundColor: "#fee727",
    color: "#fd3eb6",
    padding: "10px 0px",
  };

  const textStyle = {
    color: "var(--Pink, #fd3eb6)",
    textAlign: "center",
    fontFamily: "Londrina Solid",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
    padding: "10px 30px",
  };

  return (
    <div className="main-section">
      <div className="slider-container" style={sliderContainerStyle}>
        <Slider {...settings}>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
          <div style={textStyle}>
            <span>$DYOR</span>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default AutoPlay;
