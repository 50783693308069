// import React from "react";
// import "./style.css";
// import { Link } from "react-scroll";

// import Logo from "../../assets/New/pngImages/flatpepe.png";
// import Menu from "../../components/menu/index";
// import PinkPrism from "../../assets/Group.png";
// import YellowPrism from "../../assets/Group (1).png";
// import DarkToken from "../../assets/New/token.png";
// import BlastLogo from "../../assets/New/blastlogo.png";
// import Flatpepa from "../../assets/New/pngImages/flatpepe.png";

// const Index = () => {
//   return (
//     <>
//       <div className="header">
//         <img src={DarkToken} alt="PinkPrism" className="header-prism" />
//         <img src={Flatpepa} alt="PinkPrism" className="header-yellow-prism" />
//         <div className="header-left">
//           <Menu />
//           {/* <Logo /> */}
//           <img src={BlastLogo} className="header-left-logo" />
//         </div>
//         <div className="header-right">
//           <div className="header-right-home">
//             <a href="# " target="_blank">
//               Home
//             </a>
//           </div>
//           <div className="header-right-text">
//             <Link to="section1" smooth={true} duration={1000}>
//               <a href="# " target="_blank">
//                 Tokenomic
//               </a>
//             </Link>
//           </div>
//           <div className="header-right-text">
//             <Link to="section2" smooth={true} duration={1000}>
//               <a href="# " target="_blank">
//                 Our Mission
//               </a>
//             </Link>
//           </div>
//           <div className="header-right-shop">
//             <Link to="products" smooth={true} duration={1000}>
//               <button>SHOP NOW</button>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Index;

import React from "react";
import "./style.css";
// import { Link } from "react-scroll";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import Logo from "../../assets/newAssets/Group 65.png";
import Menu from "../../components/menu/index";

// import { Link } from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";

const Index = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="header">
        <div className="header-left">
          <Menu />
          <RouterLink to="/">
            <img src={Logo} className="header-left-logo" alt="Logo" />
          </RouterLink>
        </div>
        <div className="header-right">
          <div className="header-right-text">
            {/* <Link to="about" smooth="true" duration={1000}>
              About
            </Link> */}
            <RouterLink to="/#about" onClick={() => scrollToSection("about")}>
              About
            </RouterLink>
          </div>
          {/* <div className="header-right-text">
            <RouterLink to="/dashboard" smooth="true"  duration={1000}>
              Dashboard
            </RouterLink>
          </div> */}
          {/* <div className="header-right-text"> */}
            {/* <Link to="tokenomics" smooth="true" duration={1000}>
              Tokenomics
            </Link> */}
            {/* <RouterLink
              to="/#tokenomics"
              onClick={() => scrollToSection("tokenomics")}
            >
              Tokenomics
            </RouterLink> */}
          {/* </div> */}
          <div className="header-right-text">
            <RouterLink
              to="/#roadmap"
              onClick={() => scrollToSection("roadmap")}
            >
              Roadmap
            </RouterLink>
            {/* <Link to="roadmap" smooth="true" duration={1000}>
              Roadmap
            </Link> */}
          </div>
          {/* <div className="header-right-text">
            <Link to="mission" smooth="true" duration={1000}>
              Our Mission
            </Link>
          </div> */}
          <div className="header-right-shop">
            <a href="https://t.me/theDYORNetwork" target="blank">
              <TelegramIcon />
            </a>
            <a href="https://twitter.com/DYORNetwork" target="blank">
              <XIcon />
            </a>
            <a href="https://www.instagram.com/dyornetwork/" target="blank">
              <InstagramIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
