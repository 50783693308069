// import React from "react";
// import Header from "../../components/header/index";
// import Front from "../../components/front/index";
// import Cards from "../../components/cards/index";
// import Products from "../../components/products/index";
// import Table from "../../components/table/index";
// import Article from "../../components/article/index";
// import Empty from "../../components/empty/index";
// import Footer from "../../components/footer/index";
// import FooterBottom from "../../components/footerBottom/index";

// const Index = () => {
//   return (
//     <>
//       <section id="top">
//         <Header />
//       </section>
//       <Front />
//       <Cards />
//       <section id="products">
//         <Products />
//       </section>
//       <section id="section1">
//         <Table />
//       </section>
//       <section id="section2">
//         <Article />
//       </section>
//       <Empty />
//       <Footer />
//       <FooterBottom />
//     </>
//   );
// };

// export default Index;

// import React from "react";
import React, { useEffect } from "react";
import Header from "../../components/header/index";
import Hero from "../../components/hero/index";
import Join from "../../components/join/index";
import Tokenomics from "../../components/tokenomics/index";
import Roadmap from "../../components/roadmap/index";
// import FooterUp from "../../components/footerUp/index";
// import FooterMid from "../../components/footerMid/index";
// import FooterDown from "../../components/footerDown/index";

const Index = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <section id="top">
        <Header />
      </section>
      <section>
        <Hero />
      </section>
      {/* <div id="about">
        <Join />
      </div> */}
      <section id="about">
        <Join />
      </section>
      {/* <section id="tokenomics">
        <Tokenomics />
      </section> */}
      {/* <div id="tokenomics">
        <Tokenomics />
      </div> */}

      <section id="roadmap">
        <Roadmap />
      </section>
      {/* <FooterUp />
      <FooterMid />
      <section id="mission">
        <FooterDown />
      </section> */}
    </>
  );
};

export default Index;
