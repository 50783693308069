import React from "react";
import "./style.css";

const Index = ({ text }) => {
  return (
    <>
      <div className="phaseButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 424 73"
          fill="none"
        >
          <path
            d="M420.96 70.0464C379.538 76.6921 379.124 70.0464 337.297 70.0464C309.406 70.0464 281.519 70.0464 253.634 70.0464C225.75 70.0464 197.863 70.0464 169.971 70.0464C142.08 70.0464 114.189 70.0464 86.2979 70.0464C44.4612 70.0464 44.0467 76.6921 2.62437 70.0464C-3.26275 69.0987 2.62437 63.3407 2.62437 56.635C2.62437 49.9293 2.62437 49.9293 2.62437 43.2117C2.62437 36.494 2.62437 36.506 2.62437 29.8003C2.62437 23.0947 2.62437 23.0827 2.62437 16.377C2.62437 9.67131 -3.27337 3.90131 2.61374 2.95364C44.0361 -3.69205 44.4505 2.95364 86.2767 2.95364H337.287C379.124 2.95364 379.538 -3.69205 420.96 2.95364C426.847 3.90131 420.96 9.65932 420.96 16.365C420.96 23.0707 420.96 23.0707 420.96 29.7883C420.96 36.506 420.96 36.494 420.96 43.1997C420.96 49.9053 419.483 50.1213 420.96 56.623C422.671 64.1324 426.847 69.0987 420.96 70.0464Z"
            fill="#390E44"
          />
          <text
            x="212"
            y="55"
            font-size="48"
            fill="#fff"
            text-anchor="middle"
            className="phase-text"
          >
            {text}
          </text>
        </svg>
      </div>
    </>
  );
};

export default Index;
